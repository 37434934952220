import {DocumentNode, gql} from '@apollo/client';

const getEvents = gql`
  query GetEvents($page: Int, $size: Int) {
    items: getEvents(page: $page, size: $size) {
      id
      name
      slug
      status
      startDate
      endDate
    }
    total: _getEventsMeta {
      count
    }
  }
`;

const getEvent = gql`
  query getEvent($id: String!) {
    item: getEvent(slug: $id) {
      name
      slug
      category
      location {
        lat
        lng
      }
      zoom
      startDate
      endDate
      publicDate
      isOpen
      hidden

      settings {
        canEnter
        autoAcceptEntry
        analytic
        useSI
        useResults
        liveResultsCompetitionId
        delay
        gpsInterval
      }

      subEvents {
        id
        name
        sub
        useGPS
      }
      competitors {
        id
        name
        shortName
        startTime
        color
        subEvent {
          name
        }
        siCard
        device {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
      entries {
        id
        status
        createdAt
        user {
          firstName
          lastName
        }
        subEvent {
          id
          name
        }
      }
    }
  }
`;

const getEventSettings = gql`
  query getEventSettings($id: String!) {
    item: getEventSettings(slug: $id) {
      canEnter
      autoAcceptEntry
      analytic
      useSI
      useResults
      liveResultsCompetitionId
      delay
      gpsInterval
    }
  }
`;

const updateEvent = gql`
  mutation UpdateEvent($id: String!, $payload: EventPayload) {
    item: updateEvent(slug: $id, payload: $payload) {
      name
    }
  }
`;

const updateEventSettings = gql`
  mutation UpdateEventSettings($id: String!, $payload: EventSettingsPayload) {
    item: updateEventSettings(slug: $id, payload: $payload) {
      name
    }
  }
`;

const createEvent = gql`
  mutation CreateEvent($payload: EventPayload!) {
    item: createEvent(payload: $payload) {
      name
    }
  }
`;

const deleteEvent = gql`
  mutation DeleteMyEvent($id: String!) {
    item: deleteMyEvent(slug: $id) {
      name
    }
  }
`;

const getSubEvents = gql`
  query GetSubEvents($slug: String!, $page: Int, $size: Int) {
    items: getSubEvents(slug: $slug, page: $page, size: $size) {
      id
      name
      sub
      useGPS
    }
    total: _getSubEventsMeta(slug: $slug) {
      count
    }
  }
`;

const createSubEvent = gql`
  mutation CreateSubEvent($slug: String!, $payload: SubEventPayload!) {
    item: createSubEvent(slug: $slug, payload: $payload) {
      id
      name
      sub
      useGPS
    }
  }
`;

const updateSubEvent = gql`
  mutation UpdateSubEvent($slug: String!, $payload: SubEventPayload!) {
    item: updateSubEvent(slug: $slug, payload: $payload) {
      id
      name
      sub
      useGPS
    }
  }
`;

const deleteSubEvent = gql`
  mutation DeleteSubEven($slug: String!, $id: Int!) {
    item: deleteSubEvent(slug: $slug, subEventId: $id) {
      name
    }
  }
`;

const getSubEvent = gql`
  query GetSubEvent($id: String!) {
    item: getSubEvent(subEventId: $id) {
      id
      name
      sub
      useGPS
      event {
        name
        slug
        startDate
        settings {
          useSI
        }
        location {
          lat
          lng
        }
        zoom
      }
      controls {
        id
        name
        type
        createdAt
        position {
          lat
          lng
        }
        show
        analytic
      }
      maps {
        id
        name
        url
        createdAt
        show
        topLeft {
          lat
          lng
        }
        topRight {
          lat
          lng
        }
        bottomLeft {
          lat
          lng
        }
      }
      routes {
        id
        name
        show
        color
        track {
          lat
          lng
        }
      }
      competitors {
        id
        name
        shortName
        startTime
        color
        siCard
        device {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
    }
    getDevices {
      id
      name
    }
  }
`;

const getControl = gql`
  query GetControl($subEventId: Int!, $id: Int!) {
    item: getControl(subEventId: $subEventId, id: $id) {
      id
      name
      type
      position {
        lat
        lng
      }
      show
      analytic
    }
  }
`;

const getControls = gql`
  query GetControls($subEventId: Int!) {
    items: getControls(subEventId: $subEventId) {
      id
      name
      type
      show
      analytic
    }
    total: _getControlsMeta(subEventId: $subEventId) {
      count
    }
  }
`;

const createControl = gql`
  mutation CreateControl($subEventId: Int!, $payload: ControlPayload!) {
    item: createControl(subEventId: $subEventId, payload: $payload) {
      name
    }
  }
`;

const createManyControls = gql`
  mutation CreateManyControls($subEventId: Int!, $payload: ControlManyPayload!) {
    items: createManyControls(subEventId: $subEventId, payload: $payload) {
      name
    }
  }
`;

const deleteControl = gql`
  mutation DeleteControl($subEventId: Int!, $id: Int!) {
    item: deleteControl(subEventId: $subEventId, id: $id) {
      name
    }
  }
`;

const deleteManyControls = gql`
  mutation DeleteManyControls($subEventId: Int!, $ids: [Int!]) {
    item: deleteManyControls(subEventId: $subEventId, ids: $ids) {
      name
    }
  }
`;

const updateControl = gql`
  mutation UpdateControl($subEventId: Int!, $id: Int!, $payload: ControlPayload!) {
    item: updateControl(subEventId: $subEventId, id: $id, payload: $payload) {
      name
    }
  }
`;

const updateManyControls = gql`
  mutation UpdateManyControls($subEventId: Int!, $ids: [Int!], $payload: ControlPayload!) {
    items: updateManyControls(subEventId: $subEventId, ids: $ids, payload: $payload) {
      name
    }
  }
`;

const orderControl = gql`
  mutation OrderControl($subEventId: Int!, $id: Int!, $order: String) {
    item: orderControl(subEventId: $subEventId, id: $id, order: $order)
  }
`;

export const getUsers = gql`
  query GetUsers($filters: UserFilters) {
    items: getUsers(filters: $filters) {
      id
      firstName
      lastName
      name
      settings {
        city
        club
        siCard
      }
      role {
        name
      }
      preferredDevices {
        shortName
        color
        device {
          id
          name
        }
      }
      userDevice {
        id
        name
      }
    }
  }
`;

const getGroups = gql`
  query GetGroups {
    items: getGroups {
      id
      name
    }
  }
`;

const getCompetitorTrack = gql`
  query GetCompetitorTrack($subEventId: Int!, $id: Int!) {
    item: getCompetitorTrack(subEventId: $subEventId, id: $id) {
      track {
        lat
        lng
        time
        alt
      }
    }
  }
`;

const createCompetitorTrack = gql`
  mutation CreateCompetitorTrack($subEventId: Int!, $id: Int!, $payload: CompetitorTrackPayload) {
    item: createCompetitorTrack(subEventId: $subEventId, id: $id, payload: $payload) {
      name
    }
  }
`;

const updateCompetitorTrack = gql`
  mutation UpdateCompetitorTrack($subEventId: Int!, $id: Int!, $payload: CompetitorTrackPayload) {
    item: updateCompetitorTrack(subEventId: $subEventId, id: $id, payload: $payload) {
      name
    }
  }
`;

const deleteCompetitorTrack = gql`
  mutation DeleteCompetitorTrack($subEventId: Int!, $id: Int!) {
    item: deleteCompetitorTrack(subEventId: $subEventId, id: $id)
  }
`;

const getCompetitor = gql`
  query GetCompetitor($subEventId: Int!, $id: Int!) {
    getCompetitor(subEventId: $subEventId, id: $id) {
      id
      name
      shortName
      startTime
      color
      siCard
      device {
        id
        name
      }
      deviceId
      user {
        id
        firstName
        lastName
      }
      userId
    }
  }
`;

const getCompetitors = gql`
  query GetCompetitors($subEventId: Int!) {
    items: getCompetitors(subEventId: $subEventId) {
      id
      name
      shortName
      startTime
      color
      siCard
      device {
        id
        name
      }
      deviceId
      user {
        id
        firstName
        lastName
      }
      userId
    }
    total: _getCompetitorsMeta(subEventId: $subEventId) {
      count
    }
  }
`;

const createCompetitor = gql`
  mutation CreateCompetitor($subEventId: Int!, $payload: CompetitorPayload!) {
    item: createCompetitor(subEventId: $subEventId, payload: $payload) {
      id
      name
    }
  }
`;

const createManyCompetitors = gql`
  mutation CreateManyCompetitors($subEventId: Int!, $payload: CompetitorManyPayload!) {
    items: createManyCompetitors(subEventId: $subEventId, payload: $payload) {
      name
    }
  }
`;

const updateCompetitor = gql`
  mutation UpdateCompetitor($subEventId: Int!, $payload: CompetitorPayload!) {
    item: updateCompetitor(subEventId: $subEventId, payload: $payload) {
      name
    }
  }
`;

const deleteCompetitor = gql`
  mutation DeleteCompetitor($subEventId: Int!, $id: Int!) {
    item: deleteCompetitor(subEventId: $subEventId, competitorId: $id) {
      name
    }
  }
`;

const deleteManyCompetitors = gql`
  mutation DeleteManyCompetitors($subEventId: Int!, $ids: [Int!]) {
    item: deleteManyCompetitors(subEventId: $subEventId, ids: $ids) {
      name
    }
  }
`;

const orderCompetitor = gql`
  mutation OrderCompetitor($subEventId: Int!, $id: Int!, $order: String!) {
    item: orderCompetitor(subEventId: $subEventId, competitorId: $id, order: $order)
  }
`;

const getEntries = gql`
  query GetEntries($slug: String!, $filters: EntriesFilters) {
    items: getEntries(slug: $slug, filters: $filters) {
      id
      status
      createdAt
      userName
      subEvent {
        name
      }
    }
    total: _getEntriesMeta(slug: $slug, filters: $filters) {
      count
    }
  }
`;

const updateEntry = gql`
  mutation UpdateEntry($slug: String!, $payload: EntryPayload!) {
    item: updateEntry(slug: $slug, payload: $payload) {
      id
      status
      user {
        firstName
        lastName
      }
    }
  }
`;

const deleteEntry = gql`
  mutation DeleteEntry($slug: String!, $id: Int!) {
    item: deleteEntry(slug: $slug, id: $id) {
      id
    }
  }
`;

const getEventCompetitors = gql`
  query GetEventCompetitors($slug: String!) {
    items: getEventCompetitors(slug: $slug) {
      id
      name
      startTime
      color
      siCard
      user {
        id
      }
      subEvent {
        name
      }
    }
    total: _getEventCompetitorsMeta(slug: $slug) {
      count
    }
  }
`;

const getMap = gql`
  query GetMap($subEventId: Int!, $id: Int!) {
    item: getMap(subEventId: $subEventId, id: $id) {
      id
      name
      width
      height
      url
      topLeft {
        lat
        lng
      }
      topRight {
        lat
        lng
      }
      bottomLeft {
        lat
        lng
      }
    }
  }
`;

const getMaps = gql`
  query GetMaps($subEventId: Int!) {
    items: getMaps(subEventId: $subEventId) {
      id
      name
      url
      createdAt
      show
    }
    total: _getMapsMeta(subEventId: $subEventId) {
      count
    }
  }
`;

const createMap = gql`
  mutation CreateMap($subEventId: Int!, $payload: CreateMapPayload!) {
    item: createMap(subEventId: $subEventId, payload: $payload) {
      name
    }
  }
`;

const updateMap = gql`
  mutation UpdateMap($subEventId: Int!, $id: Int, $payload: MapPayload!) {
    item: updateMap(subEventId: $subEventId, id: $id, payload: $payload) {
      name
    }
  }
`;

const deleteMap = gql`
  mutation DeleteMap($subEventId: Int!, $id: Int!) {
    item: deleteMap(subEventId: $subEventId, id: $id) {
      name
    }
  }
`;

const orderMap = gql`
  mutation OrderMap($subEventId: Int!, $id: Int!, $order: String!) {
    item: orderMap(subEventId: $subEventId, id: $id, order: $order)
  }
`;

const getRoutes = gql`
  query GetRoutes($subEventId: Int!) {
    items: getRoutes(subEventId: $subEventId) {
      id
      name
      createdAt
      show
    }
    total: _getRoutesMeta(subEventId: $subEventId) {
      count
    }
  }
`;

const getRoute = gql`
  query GetRoute($subEventId: Int!, $id: Int!) {
    item: getRoute(subEventId: $subEventId, id: $id) {
      id
      name
      color
      show
      track {
        lat
        lng
      }
    }
  }
`;

const createRoute = gql`
  mutation CreateRoute($subEventId: Int!, $payload: RoutePayload!) {
    item: createRoute(subEventId: $subEventId, payload: $payload) {
      name
    }
  }
`;

const updateRoute = gql`
  mutation UpdateRoute($subEventId: Int!, $id: Int!, $payload: RoutePayload!) {
    item: updateRoute(subEventId: $subEventId, id: $id, payload: $payload) {
      name
    }
  }
`;

const deleteRoute = gql`
  mutation DeleteRoute($subEventId: Int!, $id: Int!) {
    item: deleteRoute(subEventId: $subEventId, id: $id) {
      name
    }
  }
`;

const orderRoute = gql`
  mutation OrderRoute($subEventId: Int!, $id: Int!, $order: String!) {
    item: orderRoute(subEventId: $subEventId, id: $id, order: $order)
  }
`;

const getEntryData = gql`
  query GetEntryData($slug: String!) {
    items: getEntryData(slug: $slug) {
      email
      name
    }
  }
`;

const createManyEntryData = gql`
  mutation CreateManyEntryData($slug: String!, $payload: EntryJsonPayload) {
    items: createManyEntryData(slug: $slug, payload: $payload) {
      email
      name
    }
  }
`;

const deleteManyEntryData = gql`
  mutation DeleteManyEntryData($slug: String!, $ids: [String!]!) {
    items: deleteManyEntryData(slug: $slug, emails: $ids) {
      name
    }
  }
`;

export const GET_ALL_DEVICES = gql`
  query GetAllDevices {
    allDevices {
      id
      name
      imei
      info
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export const getDevices = gql`
  query GetDevices {
    items: getDevices {
      id
      name
      last
      battery
      imei
    }
    total: _getDevicesMeta {
      count
    }
  }
`;

const getDevice = gql`
  query GetDevice($id: Int!) {
    item: getDevice(id: $id) {
      id
      name
      imei
      info
      userId
    }
  }
`;

const createDevice = gql`
  mutation CreateDevice($payload: DevicePayload!) {
    item: createDevice(payload: $payload) {
      name
    }
  }
`;

const updateDevice = gql`
  mutation UpdateDevice($id: Int!, $payload: DevicePayload!) {
    item: updateDevice(id: $id, payload: $payload) {
      name
    }
  }
`;

const deleteDevice = gql`
  mutation DeleteDevice($id: Int!) {
    item: deleteDevice(id: $id) {
      name
    }
  }
`;

const deleteManyDevices = gql`
  mutation DeleteManyDevices($ids: [Int!]) {
    item: deleteManyDevices(ids: $ids) {
      name
    }
  }
`;

const createGroup = gql`
  mutation CreateGroup($payload: GroupPayload) {
    item: createGroup(payload: $payload) {
      name
    }
  }
`;

const updateGroup = gql`
  mutation UpdateGroup($id: Int!, $payload: GroupPayload) {
    item: updateGroup(id: $id, payload: $payload) {
      name
    }
  }
`;

const deleteGroup = gql`
  mutation DeleteGroup($id: Int!) {
    item: deleteGroup(id: $id) {
      name
    }
  }
`;

const getGroup = gql`
  query GetGroup($id: Int!) {
    item: getGroup(id: $id) {
      id
      name
      groupUsers {
        id
        firstName
        lastName
      }
    }
  }
`;

const getGroupUsers = gql`
  query GetGroupUsers($groupId: Int!) {
    items: getGroupUsers(id: $groupId) {
      id
      name
    }
  }
`;

const createManyGroupUsers = gql`
  mutation CreateManyGroupUsers($groupId: Int!, $payload: ManyGroupUserPayload!) {
    items: createManyGroupUsers(groupId: $groupId, payload: $payload) {
      name
    }
  }
`;

const deleteGroupUser = gql`
  mutation DeleteGroupUser($groupId: Int!, $id: Int) {
    item: deleteGroupUser(groupId: $groupId, id: $id) {
      name
    }
  }
`;

const deleteManyGroupUsers = gql`
  mutation DeleteManyGroupUsers($groupId: Int!, $ids: [Int]) {
    items: deleteManyGroupUsers(groupId: $groupId, ids: $ids) {
      name
    }
  }
`;

export const GET_USERS_AND_DEVICES = gql`
  query GetUsersAndDevices {
    getUsers {
      id
      firstName
      lastName
      preferredDevices {
        shortName
        color
        device {
          id
          name
        }
      }
    }
    getDevices {
      id
      name
    }
  }
`;

export const SAVE_PREFERRED_DEVICE = gql`
  mutation SavePreferredDevice($userId: Int!, $payload: PreferredDevicePayload!) {
    savePreferredDevice(userId: $userId, payload: $payload) {
      shortName
      color
      device {
        id
        name
      }
    }
  }
`;

export const DELETE_PREFERRED_DEVICE = gql`
  mutation DeletePreferredDevice($userId: Int!) {
    deletePreferredDevice(userId: $userId) {
      shortName
    }
  }
`;

export const DASHBOARD_QUERY = gql`
  query DashboardQuery($page: Int!, $size: Int!) {
    myEvents(page: $page, size: $size) {
      name
      slug
      status
      startDate
      endDate
    }
    waitingEntries {
      id
      createdAt
      user {
        firstName
        lastName
      }
      event {
        slug
        name
      }
    }
  }
`;

const getLogos = gql`
  query getLogos($slug: String!) {
    items: getLogos(slug: $slug) {
      id
      name
      url
      show
    }
  }
`;

const createLogo = gql`
  mutation CreateLogo($slug: String!, $payload: LogoPayload!) {
    item: createLogo(slug: $slug, payload: $payload) {
      name
    }
  }
`;

const updateLogo = gql`
  mutation UpdateLogo($slug: String!, $id: Int!, $payload: LogoPayload!) {
    item: updateLogo(slug: $slug, id: $id, payload: $payload) {
      name
    }
  }
`;

const deleteLogo = gql`
  mutation DeleteLogo($slug: String!, $id: Int!) {
    item: deleteLogo(slug: $slug, id: $id) {
      name
    }
  }
`;

export const queries: {[key: string]: DocumentNode} = {
  getEvents,
  getEvent,
  createEvent,
  updateEvent,
  deleteEvent,
  getEventSettings,
  updateEventSettings,
  getSubEvents,
  createSubEvent,
  updateSubEvent,
  deleteSubEvent,
  getSubEvent,
  getControl,
  getControls,
  createControl,
  createManyControls,
  deleteControl,
  deleteManyControls,
  updateControl,
  updateManyControls,
  orderControl,
  getUsers,
  getGroups,
  getCompetitorTrack,
  createCompetitorTrack,
  updateCompetitorTrack,
  deleteCompetitorTrack,
  getCompetitor,
  getCompetitors,
  createCompetitor,
  createManyCompetitors,
  updateCompetitor,
  deleteCompetitor,
  deleteManyCompetitors,
  orderCompetitor,
  getEntries,
  updateEntry,
  deleteEntry,
  getEventCompetitors,
  getMap,
  getMaps,
  createMap,
  updateMap,
  deleteMap,
  orderMap,
  getRoutes,
  getRoute,
  createRoute,
  updateRoute,
  deleteRoute,
  orderRoute,
  getEntryData,
  createManyEntryData,
  deleteManyEntryData,
  GET_ALL_DEVICES,
  getDevices,
  createDevice,
  updateDevice,
  deleteDevice,
  deleteManyDevices,
  getDevice,
  createGroup,
  updateGroup,
  deleteGroup,
  getGroup,
  getGroupUsers,
  createManyGroupUsers,
  deleteGroupUser,
  deleteManyGroupUsers,
  GET_USERS_AND_DEVICES,
  SAVE_PREFERRED_DEVICE,
  DELETE_PREFERRED_DEVICE,
  DASHBOARD_QUERY,
  getLogos,
  createLogo,
  updateLogo,
  deleteLogo
};

export default queries;
